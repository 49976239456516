var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalPagarComision",attrs:{"titulo":"Pagar Comisión","icon":"money","tamano":"modal-lg","no-aceptar":"","adicional":"Pagar"},on:{"adicional":_vm.pagarComision}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-auto"}),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"f-15 text-general pl-3"},[_vm._v("Periodo de comisión")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"periodo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{attrs:{"format":"dd-MM-yyyy","value-format":"yyyy-MM-dd","type":"daterange","unlink-panels":"","popper-class":"date-picker-edit-popper","start-placeholder":"Fecha inicio","end-placeholder":"Fecha fin"},model:{value:(_vm.tiempo_vigencia),callback:function ($$v) {_vm.tiempo_vigencia=$$v},expression:"tiempo_vigencia"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"bg-light-f5 cr-pointer text-general2 px-3 br-10 py-2 text-center shadow mt-3",on:{"click":_vm.getComisionTeorica}},[_vm._v(" Cargar datos de comisión ")])])]),(_vm.clicBoton > 0)?_c('div',{staticClass:"row mx-0 my-2"},[_c('div',{staticClass:"col"},[_c('i',{staticClass:"icon-receipt text-general2 f-18"}),_c('span',{staticClass:"text-general"},[_vm._v(_vm._s(_vm.agregarSeparadoresNumero(_vm.comisionesTeoricas.pedidos,0))+" Pedidos")])]),_c('div',{staticClass:"col"},[_c('i',{staticClass:"icon-currency-usd-circle text-general2 f-18"}),_c('span',{staticClass:"text-general"},[_vm._v("Ventas: "+_vm._s(_vm.separadorNumero(_vm.comisionesTeoricas.ventas)))])]),_c('div',{staticClass:"col"},[_c('i',{staticClass:"icon-cash-multiple text-general2 f-18"}),_c('span',{staticClass:"text-general"},[_vm._v("Comisión: "+_vm._s(_vm.separadorNumero(_vm.comisionesTeoricas.comisiones)))])])]):_vm._e(),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col my-3"},[_c('ValidationProvider',{attrs:{"rules":"required|greaterThanZero","name":"valor del pago"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-general f-15 pl-3 py-1"},[_vm._v(" Valor del pago ")]),_c('div',{staticClass:"d-middle"},[_c('div',{staticClass:"bg-whitesmoke2 py-1 px-1 mr-1 border",staticStyle:{"border-radius":"12px 0px 0px 12px"}},[_vm._v(" "+_vm._s(_vm.sigla)+" ")]),_c('input-miles-moneda',{attrs:{"id-moneda":_vm.monedaLeechero,"placeholder":"Valor del pago","maxlength":"10"},model:{value:(_vm.model.valor),callback:function ($$v) {_vm.$set(_vm.model, "valor", $$v)},expression:"model.valor"}})],1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col my-3"},[_c('p',{staticClass:"text-general f-15 pl-3 py-1"},[_vm._v(" Fecha del pago ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"fecha del pago"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","placeholder":"Fecha del pago","picker-options":_vm.pickerOptions},model:{value:(_vm.model.fecha_pago),callback:function ($$v) {_vm.$set(_vm.model, "fecha_pago", $$v)},expression:"model.fecha_pago"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 my-3"},[_c('p',{staticClass:"f-15 text-general pl-3"},[_vm._v(" Observación ")]),_c('ValidationProvider',{attrs:{"rules":"required|max:500","name":"observación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"textarea","rows":4,"maxlength":"500","show-word-limit":""},model:{value:(_vm.model.observacion),callback:function ($$v) {_vm.$set(_vm.model, "observacion", $$v)},expression:"model.observacion"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])]),_c('div',{staticClass:"col-auto"})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }