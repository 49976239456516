<template>
    <modal ref="modalPagarComision" titulo="Pagar Comisión" icon="money" tamano="modal-lg" no-aceptar adicional="Pagar" @adicional="pagarComision">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col">
                    <div class="row mx-0">
                        <div class="col">
                            <p class="f-15 text-general pl-3">Periodo de comisión</p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="periodo">
                                <el-date-picker
                                v-model="tiempo_vigencia"
                                format="dd-MM-yyyy"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                unlink-panels
                                popper-class="date-picker-edit-popper"
                                start-placeholder="Fecha inicio"
                                end-placeholder="Fecha fin"
                                />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <div class="bg-light-f5 cr-pointer text-general2 px-3 br-10 py-2 text-center shadow mt-3" @click="getComisionTeorica">
                                Cargar datos de comisión
                            </div>
                        </div>
                    </div>
                    <div v-if="clicBoton > 0" class="row mx-0 my-2">
                        <div class="col">
                            <i class="icon-receipt text-general2 f-18" />
                            <span class="text-general">{{ agregarSeparadoresNumero(comisionesTeoricas.pedidos,0) }} Pedidos</span>
                        </div>
                        <div class="col">
                            <i class="icon-currency-usd-circle text-general2 f-18" />
                            <span class="text-general">Ventas: {{ separadorNumero(comisionesTeoricas.ventas) }}</span>
                        </div>
                        <div class="col">
                            <i class="icon-cash-multiple text-general2 f-18" />
                            <span class="text-general">Comisión: {{ separadorNumero(comisionesTeoricas.comisiones) }}</span>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|greaterThanZero" name="valor del pago">
                                <p class="text-general f-15 pl-3 py-1">
                                    Valor del pago
                                </p>
                                <div class="d-middle">
                                    <div class="bg-whitesmoke2 py-1 px-1 mr-1 border" style="border-radius:12px 0px 0px 12px">
                                        {{ sigla }}
                                    </div>
                                    <input-miles-moneda v-model="model.valor" :id-moneda="monedaLeechero" placeholder="Valor del pago" maxlength="10" />
                                    <!-- <el-input v-model="model.valor" placeholder="Valor del pago" class="w-100 br-10" />                             -->
                                </div>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col my-3">
                            <p class="text-general f-15 pl-3 py-1">
                                Fecha del pago
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="fecha del pago">
                                <el-date-picker v-model="model.fecha_pago" class="w-100" type="date" placeholder="Fecha del pago" :picker-options="pickerOptions" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 my-3">
                            <p class="f-15 text-general pl-3">
                                Observación
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:500" name="observación">
                                <el-input v-model="model.observacion" type="textarea" :rows="4" maxlength="500" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-auto" />
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import moment from 'moment'
import Comisiones from "~/services/comisiones";
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            tiempo_vigencia: [],
            model:{
                id: null,
                valor: null,
                fecha_inicio: null,
                fecha_fin: null,
                fecha_pago: null,
                observacion: null,
                id_tienda: null,
            },
            idLeechero: null,
            monedaLeechero: null,
            pickerOptions:{
                disabledDate(date){
                    return moment(date).isBefore(moment().subtract(1,'week').subtract(1,'day')) || moment(date).isAfter(moment())
                }
            },
            clicBoton:0,
            comisionesTeoricas:{
                pedidos: 0,
                ventas: 0,
                comisiones: 0,
            }
        }
    },
    computed: {
        ...mapGetters({
            monedas:'cedis/monedas',
        }),
        sigla(){
            let sigla = ''
            let moneda = this.monedas.find(el => el.id == this.monedaLeechero)
            sigla = moneda ? moneda.sigla : ''
            return sigla
        }
    },
    methods: {
        toggle(idLeechero, monedaLeechero){
            this.limpiar()
            this.monedaLeechero = monedaLeechero
            this.idLeechero = idLeechero
            this.$refs.modalPagarComision.toggle();
        },
        async getComisionTeorica(){
            try {
                if(this.tiempo_vigencia.length == 0){
                    this.notificacion('Mensaje', 'Debes digitar un rango de fecha para cotizar la comisión', 'warning')
                    return false
                }
                let params = {
                    id_moneda: this.monedaLeechero,
                    fecha_inicio: moment(this.tiempo_vigencia[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.tiempo_vigencia[1]).format('Y-MM-DD'),
                }
                const {data} = await Comisiones.getComisionTeorica(this.idLeechero, params)
                this.comisionesTeoricas = data.comisionesTeoricas
                this.clicBoton++
            } catch (e){
                this.error_catch(e)
            }
        },
        async pagarComision(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.model.fecha_inicio = moment(this.tiempo_vigencia[0]).format('Y-MM-DD')
                this.model.fecha_fin = moment(this.tiempo_vigencia[1]).format('Y-MM-DD')
                this.model.fecha_pago = moment(this.model.fecha_pago).format('Y-MM-DD')
                this.model.id_tienda = this.idLeechero

                const {data} = await Comisiones.pagarComision(this.model)
                this.notificacion('Mensaje', data.error ?? data.mensaje, data.error ? 'warning' : 'success')
                if(!data.error){
                    this.$emit('listar')
                    this.$refs.modalPagarComision.toggle()
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.clicBoton = 0
            this.tiempo_vigencia = []
            this.model = {
                id: null,
                valor: null,
                fecha_inicio: null,
                fecha_fin: null,
                fecha_pago: null,
                observacion: null,
                id_tienda: null,
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
